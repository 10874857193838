import React, { useState } from 'react';
import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Dialog from '../../atoms/Dialog/Dialog';
import { bcApi } from '../../../helpers/bigcommerce';

import * as styles from './GetCertificateCodeForm.module.css';

const validForm = data => {
  if (!data.gift_certificate_code) {
    return 'Certificate Code is required.';
  }
};

const GetCertificateCodeForm = () => {
  const [values, setValues] = useState({
    gift_certificate_code: '',
    balance: null
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [msg, setMessage] = useState({ error: '', success: '' });

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const onSubmit = () => {
    const validationMsg = validForm(values);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }

    bcApi(
      `gift_certificates?code=${values.gift_certificate_code}`,
      'GET',
      null,
      2
    ).then(({ response, status }) => {
      if (status === 200) {
        setValues({
          ...values,
          balance: response[0]
        });
      } else {
        setMessage({
          error: 'Sorry, something went wrong. Try again later.'
        });
      }
    });
  };

  return (
    <div className={styles.purchaseform}>
      {msg.error && <p className={styles.error}>{msg.error}</p>}

      {values.balance && (
        <div>
          <p className={styles.balancePrice}>
            <CurrencyFormatter
              currency={values.balance.currency_code}
              amount={values.balance.balance}
              ignoreTax={true}
            />
          </p>

          <p className={styles.giftCode}>{values.gift_certificate_code}</p>
        </div>
      )}
      <div className={styles.formWrapp}>
        <FormInputField
          id="gift_certificate_code"
          type="input"
          labelName="Gift Certificate Code"
          value={values['gift_certificate_code']}
          handleChange={onHandleChange}
        />

        <Button
          className={styles.checkBalanceButton}
          type="span"
          level="tertiary"
          onClick={() => onSubmit()}
        >
          Check Balance
        </Button>
      </div>

      <Dialog
        hideBtnCancel={true}
        size="sm"
        open={dialogOpen}
        dividers={false}
        hideBtnOk={true}
        onCancel={() => setDialogOpen(false)}
      >
        {msg.success && (
          <p className={`${styles.success} ${styles.dialogContent}`}>
            {msg.success}
          </p>
        )}
        {msg.error && (
          <p className={`${styles.error} ${styles.dialogContent}`}>
            {msg.error}
          </p>
        )}
      </Dialog>
    </div>
  );
};

export default GetCertificateCodeForm;
