import React from 'react';
import * as pcStyles from './Breadcrumbs.module.css';
import * as mpsStyles from './Breadcrumbs.mps.module.scss';
import Icon from '../Icon/Icon';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const Breadcrumbs = ({ crumbs }) => {
  let crumbsOutput = crumbs;
  if (crumbsOutput && typeof crumbsOutput !== 'object') {
    if (crumbsOutput.indexOf('>') > -1) {
      crumbsOutput = crumbsOutput.split('>');
    } else {
      crumbsOutput = [crumbsOutput];
    }
  }
  return (
    <div className={styles.root}>
      {crumbsOutput &&
        crumbsOutput.map((crumb, crumbIndex) => (
          <span key={crumbIndex}>
            {crumbIndex > 0 && <span className={styles.spacer}>&nbsp;</span>}
            {typeof crumb === 'object' && !crumb.label && (
              <a className={styles.crumb} href={crumb.link}>
                <Icon symbol="home" />
              </a>
            )}
            {typeof crumb === 'object' && 'link' in crumb && crumb.link !== '' && (
              <a className={styles.crumb} href={crumb.link}>
                {crumb.label.trim()}
              </a>
            )}
            {typeof crumb === 'object' && (!('link' in crumb) || crumb.link === '') && (
              <span className={`${styles.crumb} ${styles.lastCrumb}`}>{crumb.label.trim()}</span>
            )}
            {typeof crumb !== 'object' && <span className={styles.crumb}>{crumb.trim()}</span>}
          </span>
        ))}
    </div>
  );
};

export default Breadcrumbs;
