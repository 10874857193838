// extracted by mini-css-extract-plugin
export var conditionalIconPadding = "FormInputField-module--conditionalIconPadding--fc5ff";
export var count = "FormInputField-module--count--b39df";
export var fieldRequired = "FormInputField-module--fieldRequired--740f6";
export var formField = "FormInputField-module--formField--4c942";
export var iconContainer = "FormInputField-module--iconContainer--4ed5f";
export var input = "FormInputField-module--input--bf835";
export var label = "FormInputField-module--label--c1384";
export var note = "FormInputField-module--note--83be9";
export var offsetIcon = "FormInputField-module--offsetIcon--01068";
export var textarea = "FormInputField-module--textarea--f3b58";