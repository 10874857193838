import React from 'react';
import GetCertificateCodeForm from '../../components/molecules/GetCertificateCodeForm/GetCertificateCodeForm';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import Layout from '../../components/organisms/Layout/Layout';
import Seo from '../../components/organisms/Seo/Seo';

import * as styles from './giftCertificates.module.css';

const Title = ({ children }) => (
  <div className={styles.redeemTitle}>{children}</div>
);

const CheckGiftCertificateBalance = () => (
  <div className='no-bottom-content'>
    <Title>
      You can check the balance of a gift certificate by typing the code in to
      the box below.
    </Title>
    <div>
      <GetCertificateCodeForm />
    </div>
  </div>
);

const CheckGiftCertificateBalancePage = () => {
  return (
    <Layout>
      <Seo title="Check Gift Card Balance" />

      <GiftCertificatesTab pageId={'check-gift-certificate-balance'}>
        <CheckGiftCertificateBalance />
      </GiftCertificatesTab>
    </Layout>
  );
};

export default CheckGiftCertificateBalancePage;
