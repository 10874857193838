import {
  Box,
  Divider,
  Flex,
  Image,
  Select,
  Text,
  VStack,
  useMediaQuery
} from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React from 'react';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../atoms/Container/Container';
import * as styles from './GiftCertificatesTab.module.scss';
import { isMadPawsStore } from '../../../common/util';

const subMenuData = [
  {
    itemKey: 'purchase-gift-certificate',
    label: 'Purchase gift card',
    uri: '/gift-certificates/'
  },
  {
    itemKey: 'redeem-gift-certificate',
    label: 'Redeem gift card',
    uri: '/gift-certificates/redeem/'
  },
  {
    itemKey: 'check-gift-certificate-balance',
    label: 'Check balance',
    uri: '/gift-certificates/check-balance/'
  }
];

const GiftCertificatesTab = ({ children }) => {
  const [isTablet] = useMediaQuery('(max-width: 767px)');
  const  { pathname } = useLocation()

  return (
    <>
      <Box bg={isMadPawsStore() ? 'var(--primary-default)' : '#1990BA'} color={'#fff'}>
        <Container size="large">
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={['column', 'column', 'row']}
            pt={[16, 16,0]}
            minH={'235px'}
          >
            <h2 className="m-0">Purchase a Gift Card</h2>
            <Image pt={[8, 8,0]} alignSelf={'flex-end'} src={isMadPawsStore() ? "/giftcard_mps.png" : "/giftcard.png"} alt="gift" m={0} maxH={'220px'} />
          </Flex>
        </Container>
      </Box>

      <Container size="large">
        <Breadcrumbs
          crumbs={[{ link: '/', label: '' }, { label: 'Gift Cards' }]}
        />

        <Flex
          mt={8}
          gap={['2rem', '2rem', '8rem']}
          direction={['column', 'column', 'row']}
        >
          {isTablet ? (
            <Select
              rounded={'full'}
              onChange={e => navigate(e.currentTarget.value)}
            >
              {subMenuData.map(route => (
                <option
                  key={route.uri}
                  value={route.uri}
                  selected={pathname === route.uri}
                >
                  {route.label}
                </option>
              ))}
            </Select>
          ) : (
            <VStack
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              minW={180}
              flexShrink={0}
            >
              <Text fontWeight={400}>Gift Cards</Text>
              <Divider my={1} />
              {subMenuData.map(item => (
                <Link
                  className={`underline ${styles.links}`}
                  key={item.itemKey}
                  to={item.uri}
                  activeClassName="link-active"
                >
                  <Text as={'span'}>{item.label}</Text>
                </Link>
              ))}
            </VStack>
          )}
          {children}
        </Flex>
      </Container>
    </>
  );
};

export default GiftCertificatesTab;
